<template>
  <div id="page">
    <!-- <Menu /> -->
    <div class="layout crumbwrap">
      <div class="container">
        <div class="crumb">
          <div class="f14 text-gray-light" v-if="breadcrumbs">
            <span class="icon-home text-blue"></span>
            <router-link to="/">首页</router-link> >>
            <!-- <a href="/huiyuan/">{{ breadcrumb1.title }}</a> -->
            <router-link
              :to="{
                path: '/' + breadcrumb1.urlname,
                query: {},
                component: () =>
                  import(
                    '@/components/list/' + breadcrumb1.listtemplate + '.vue'
                  ),
              }"
              @click.enter="leftclick1(breadcrumb1)"
              >{{ breadcrumb1.title }}</router-link
            >
            >>
            <!-- <a href="/fengcai/">{{ breadcrumb2.title }}</a> -->
            <router-link
              :to="{
                path: '/' + breadcrumb1.urlname + '/' + breadcrumb2.urlname,
                query: {},
                component: () =>
                  import(
                    '@/components/list/' + breadcrumb2.listtemplate + '.vue'
                  ),
              }"
              @click.enter="leftclick2(breadcrumb2, breadcrumb1)"
              >{{ breadcrumb2.title }}</router-link
            >
          </div>
          <div class="f14 text-gray-light" v-else>
            <span class="icon-home text-blue"></span>
            <router-link to="/">首页</router-link> >>
            <router-link
              :to="{
                path: '/' + breadcrumb1.urlname,
                query: {},
                component: () =>
                  import(
                    '@/components/list/' + breadcrumb1.listtemplate + '.vue'
                  ),
              }"
              @click.enter="leftclick3(breadcrumb1)"
              >{{ breadcrumb1.title }}</router-link
            >
          </div>
        </div>
      </div>
    </div>
    <div class="layout tWrap">
      <div class="container f16">
        <div class="line-large">
          <div class="xs3">
            <div class="sidebar side_sb">
              <h3 class="tit text-white text-center f16 fs100">
                {{ leftlist.title }}
              </h3>
              <div class="snav">
                <ul v-for="(item, index) in leftlist.children" :key="index">
                  <li>
                    <!-- <router-link to="/hangye/pingxuan">组织架构</router-link> -->
                    <router-link
                      :to="{
                        path: '/' + leftlist.urlname + '/' + item.urlname,
                        query: {},
                        component: () =>
                          import(
                            '@/components/list/' + item.listtemplate + '.vue'
                          ),
                      }"
                      @click.enter="leftclick4(item, leftlist)"
                      >{{ item.title }}</router-link
                    >
                  </li>
                  <!-- <li><a href="/pingxuan/">组织架构</a></li> -->
                  <!-- <li>
                    <router-link to="/hangye/rencai">协会领导</router-link>
                  </li>
                  <li>
                    <router-link to="/hangye/contact">联系我们</router-link>
                  </li> -->
                  <!-- <li><a href="/rencai/">协会领导</a></li> -->
                </ul>
              </div>
            </div>
            <div class="sideqrcode margin-big-top">
              <img
                src="https://awhf-oss-img.oss-cn-beijing.aliyuncs.com/homeassociation/logo_phone.3d8fd235.png"
                referrerpolicy="no-referrer"
                alt=""
                style="width: 100%"
              />
            </div>
          </div>
          <!-- -------------------- -->
          <div class="xs9">
            <div class="ctitle">
              <div class="wrap">
                <span class="f16 fs100 text-white">{{ centers.title }}</span>
              </div>
            </div>
            <div v-if="listtemplate == 'newslist'">
              <el-tabs
                v-if="centers.urlname == 'dashiji'"
                v-model="activeNamedashiji"
                class="demo-tabs"
                @tab-click="handleClickdashiji"
              >
                <el-tab-pane style="width: 100%" label="国家" name="first">
                </el-tab-pane>
                <el-tab-pane
                  style="width: 100%"
                  label="省级"
                  name="second"
                ></el-tab-pane>
                <el-tab-pane
                  style="width: 100%"
                  label="市级"
                  name="seconds"
                ></el-tab-pane>
              </el-tabs>
              <div
                class="sousuo"
                style="padding: 0px 0px 10px 0; display: flex"
                v-if="centers.urlname == 'hunlians'"
              >
                <el-input
                  style="width: 20%"
                  v-model="age1"
                  @input="ageinput1"
                  type="number"
                  placeholder="请输入最小年龄限制"
                  clearable
                />
                <div
                  style="padding-left: 10px; display: flex; align-items: center"
                >
                  -
                </div>
                <el-input
                  style="padding-left: 10px; width: 20%"
                  v-model="age2"
                  type="number"
                  @input="ageinput2"
                  placeholder="请输入最大年龄限制"
                  clearable
                />
                <el-select
                  style="padding-left: 10px; width: 30%"
                  v-model="sexlist"
                  class="m-2"
                  placeholder="请选择性别"
                  size="large"
                  @change="sexchange"
                  clearable
                >
                  <el-option
                    v-for="item in sexoptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                    clearable
                  />
                </el-select>
              </div>
              <!-- <div v-if="centers.urlname != 'dashiji'"> -->
              <div>
                <div v-if="centers.urlname == 'hunlians'">
                  <div v-if="flielist.length != 0">
                    <div style="font-size: 15px; color: #0168ad">
                      如有意愿请牢记编号并联系:13191626466；微信:a13191626466
                    </div>
                    <div
                      class="newslist"
                      v-for="(item, index) in tableData()"
                      :key="index"
                    >
                      <dl @click="newsclick(item)">
                        <dd class="date text-gray">
                          <span>{{ item.day }}</span
                          >{{ item.year_month }}
                        </dd>
                        <dt class="f18 fn text-more">
                          <span>编号：{{ item.id }}</span>
                        </dt>
                        <dd
                          class="desc text-gray f14"
                          style="display: flex; align-items: center"
                        >
                          {{ item.sex == 1 ? "男" : "女" }}，{{
                            item.age
                          }}，择偶要求：
                          <div
                            style="
                              width: 500px;
                              white-space: nowrap;
                              overflow: hidden;
                              text-overflow: ellipsis;
                            "
                          >
                            {{ item.matchmakingrequirements }}
                          </div>
                        </dd>
                      </dl>
                    </div>

                    <div class="pagebar">
                      <div class="pagination">
                        <el-pagination
                          background
                          :page-size="5"
                          layout="prev, pager, next"
                          :total="flielist.length"
                          @size-change="handleSizeChange"
                          @current-change="handleCurrentChange"
                        />
                      </div>
                    </div>
                  </div>
                  <div v-else style="text-align: center">暂无数据</div>
                </div>
                <div v-else>
                  <div v-if="flielist.length != 0">
                    <div
                      class="newslist"
                      v-for="(item, index) in tableData()"
                      :key="index"
                    >
                      <dl @click="newsclick(item)">
                        <dd class="date text-gray">
                          <span>{{ item.day }}</span
                          >{{ item.year_month }}
                        </dd>
                        <dt class="f18 fn text-more">
                          <span>{{ item.title }}</span>
                        </dt>
                        <dd class="desc text-gray f14">
                          {{ item.title }}
                        </dd>
                      </dl>
                    </div>

                    <div class="pagebar">
                      <div class="pagination">
                        <el-pagination
                          background
                          :page-size="5"
                          layout="prev, pager, next"
                          :total="flielist.length"
                          @size-change="handleSizeChange"
                          @current-change="handleCurrentChange"
                        />
                      </div>
                    </div>
                  </div>
                  <div v-else style="text-align: center">暂无数据</div>
                </div>
              </div>

              <!-- </div> -->
            </div>
            <div v-if="listtemplate == 'imageslist'">
              <div
                class="sousuo"
                style="padding: 0px 0px 10px 0; display: flex"
                v-if="centers.urlname == 'jiazheng'"
              >
                <el-select
                  style="width: 30%"
                  v-model="servicecate"
                  class="m-2"
                  placeholder="请选择家政人员类别"
                  size="large"
                  @change="selectchange"
                  clearable
                >
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                    clearable
                  />
                </el-select>
                <el-input
                  style="padding-left: 10px"
                  v-model="thname"
                  @input="thnameinput"
                  placeholder="请输入搜索条件"
                  clearable
                />
              </div>
              <div
                class="sousuo"
                style="padding: 0px 0px 10px 0; display: flex"
                v-if="centers.urlname == 'vipmess'"
              >
                <el-select
                  style="width: 30%"
                  v-model="viplist"
                  class="m-2"
                  placeholder="请选择会员类别"
                  size="large"
                  @change="vipchange"
                  clearable
                >
                  <el-option
                    v-for="item in vipoptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                    clearable
                  />
                </el-select>
                <el-input
                  style="padding-left: 10px"
                  v-model="inname"
                  @input="innameinput"
                  placeholder="请输入搜索条件"
                  clearable
                />
                <!-- <el-input
                  v-model="inname"
                  @input="innameinput"
                  placeholder="请输入搜索条件"
                  clearable
                /> -->
              </div>
              <div
                class="sousuo"
                style="padding: 0px 0px 10px 0"
                v-if="centers.urlname == 'hospital'"
              >
                <el-input
                  v-model="honame"
                  @input="honameinput"
                  placeholder="请输入搜索条件"
                  clearable
                />
              </div>
              <!-- <el-tabs
                v-if="centers.urlname == 'fengcai'"
                v-model="activeNamefengcai"
                class="demo-tabs"
                @tab-click="handleClickfengcai"
              >
                <el-tab-pane style="width: 100%" label="免费区" name="first">
                </el-tab-pane>
                <el-tab-pane style="width: 100%" label="收费区" name="second">
                </el-tab-pane>
              </el-tabs> -->
              <div>
                <div v-if="centers.urlname == 'fengcai'">
                  <div v-if="flielist.length != 0">
                    <div class="albumlist">
                      <ul class="line-big clearfix">
                        <li
                          class="xm4 xs6 xl6"
                          v-for="(item, index) in tableData1()"
                          :key="index"
                          @click="imagesclick(item)"
                        >
                          <div
                            v-if="item.tabfengcai == '2'"
                            style="
                              position: absolute;
                              right: 10px;
                              background: #ffb53f;
                              font-size: 12px;
                              color: #ffffff;
                              padding: 0 3px;
                              border-radius: 4px;
                            "
                          >
                            优质
                          </div>
                          <img
                            :src="item.cover"
                            referrerpolicy="no-referrer"
                            alt="临西医养结合中心"
                            class="pic"
                            style="height: 210px"
                          />
                          <span class="tit f14 text-center text-more">{{
                            item.title
                          }}</span>
                        </li>
                      </ul>
                    </div>

                    <div class="pagebar">
                      <div class="pagination">
                        <el-pagination
                          background
                          :page-size="9"
                          layout="prev, pager, next"
                          :total="flielist.length"
                          @size-change="handleSizeChange"
                          @current-change="handleCurrentChange"
                        />
                      </div>
                    </div>
                  </div>

                  <div v-else style="text-align: center">暂无数据</div>
                </div>
                <div v-else-if="centers.urlname == 'fuli'">
                  <div v-if="flielist.length != 0">
                    <div class="albumlist">
                      <ul class="line-big clearfix">
                        <li
                          class="xm4 xs6 xl6"
                          v-for="(item, index) in tableData1()"
                          :key="index"
                          style="cursor: pointer"
                          @click="imgshow = true"
                        >
                          <img
                            src="https://awhf-oss-img.oss-cn-beijing.aliyuncs.com/homeassociation/preferentialbag.png"
                            referrerpolicy="no-referrer"
                            alt="临西医养结合中心"
                            class="pic"
                            style="height: 110px"
                          />
                          <div
                            style="
                              position: absolute;
                              top: 0px;
                              left: 0px;
                              display: flex;
                              justify-content: center;
                              align-items: center;
                              width: 93%;
                              margin-left: 12px;
                              height: 106px;
                            "
                          >
                            <div
                              style="
                                width: 30%;
                                text-align: center;
                                color: #ffffff;
                              "
                            >
                              ￥<span style="font-size: 28px">{{
                                item.price
                              }}</span>
                            </div>
                            <div style="width: 70%; padding-left: 20px">
                              <div
                                style="
                                  height: 99px;
                                  font-size: 20px;
                                  font-weight: bold;
                                  padding-right: 10px;
                                  line-height: 30px;
                                  padding-top: 10px;
                                "
                              >
                                {{ item.cname }}
                              </div>
                              <div
                                style="
                                  position: absolute;
                                  bottom: 0;
                                  font-size: 12px;
                                  height: 28px;
                                  color: #b1b1b1;
                                "
                              >
                                {{ item.expiretime }}过期
                              </div>
                            </div>
                          </div>
                          <!-- <span class="tit f14 text-center text-more">{{ item.title }}</span> -->
                        </li>
                      </ul>
                    </div>
                    <div
                      v-if="imgshow"
                      @click="imgshow = false"
                      style="
                        background: #ffffff;
                        border: 1px #d0d0d0 solid;
                        position: absolute;
                        width: 250px;
                        text-align: center;
                        height: 250px;
                        top: 50%;
                        left: 27%;
                        border-radius: 18px;
                      "
                    >
                      <div
                        style="
                          height: 250px;
                          display: flex;
                          align-items: center;
                          justify-content: center;
                        "
                      >
                        <img
                          :src="img"
                          referrerpolicy="no-referrer"
                          alt="临西医养结合中心"
                          class="pic"
                          style="height: 200px"
                        />
                        <div
                          style="
                            position: absolute;
                            bottom: 0;
                            font-size: 12px;
                            color: #969696;
                          "
                        >
                          点击二维码关闭弹窗
                        </div>
                      </div>
                    </div>

                    <div class="pagebar">
                      <div class="pagination">
                        <el-pagination
                          background
                          :page-size="9"
                          layout="prev, pager, next"
                          :total="flielist.length"
                          @size-change="handleSizeChange"
                          @current-change="handleCurrentChange"
                        />
                      </div>
                    </div>
                  </div>

                  <div v-else style="text-align: center">暂无数据</div>
                </div>
                <div v-else-if="centers.urlname == 'chuangye'">
                  <div v-if="flielist.length != 0">
                    <div class="albumlist">
                      <ul class="line-big clearfix">
                        <li
                          class="xm4 xs6 xl6"
                          v-for="(item, index) in tableData1()"
                          :key="index"
                          @click="jiaxieshow = true"
                        >
                          <img
                            :src="item.cover"
                            referrerpolicy="no-referrer"
                            alt="临西医养结合中心"
                            class="pic"
                            style="height: 210px"
                          />
                          <span class="tit f14 text-center text-more">{{
                            item.title
                          }}</span>
                        </li>
                      </ul>
                    </div>
                    <div
                      v-if="jiaxieshow"
                      @click="jiaxieshow = false"
                      style="
                        background: #ffffff;
                        border: 1px #d0d0d0 solid;
                        position: absolute;
                        width: 250px;
                        text-align: center;
                        height: 250px;
                        top: 28%;
                        left: 27%;
                        border-radius: 18px;
                      "
                    >
                      <div
                        style="
                          height: 250px;
                          display: flex;
                          align-items: center;
                          justify-content: center;
                        "
                      >
                        <img
                          src="https://awhf-oss-img.oss-cn-beijing.aliyuncs.com/homeassociation/zhigong.png"
                          referrerpolicy="no-referrer"
                          alt="家协直供商城"
                          class="pic"
                          style="height: 200px"
                        />
                        <div
                          style="
                            position: absolute;
                            bottom: 0;
                            font-size: 12px;
                            color: #969696;
                          "
                        >
                          点击二维码关闭弹窗
                        </div>
                      </div>
                    </div>

                    <div class="pagebar">
                      <div class="pagination">
                        <el-pagination
                          background
                          :page-size="9"
                          layout="prev, pager, next"
                          :total="flielist.length"
                          @size-change="handleSizeChange"
                          @current-change="handleCurrentChange"
                        />
                      </div>
                    </div>
                  </div>

                  <div v-else style="text-align: center">暂无数据</div>
                </div>
                <div v-else-if="centers.urlname == 'jiazheng'">
                  <div v-if="flielist.length != 0">
                    <div class="albumlist">
                      <ul class="line-big clearfix">
                        <li
                          class="xm4 xs6 xl6 conzhezhao"
                          v-for="(item, index) in tableData1()"
                          :key="index"
                        >
                          <div class="zhezhao">
                            <!-- <img
                              :src="item.qrcode"
                              referrerpolicy="no-referrer"
                              alt="临西医养结合中心"
                              class="pic peopel"
                              style="
                                height: 50px;
                                width: 50px;
                                border-radius: 0px 0px 100% 0px;
                                cursor: pointer;
                              "
                            /> -->
                            <img
                              :src="item.qrcode"
                              referrerpolicy="no-referrer"
                              alt="临西医养结合中心"
                              class="pic peopelwei"
                              style="height: 150px; width: 150px"
                            />
                          </div>
                          <div
                            style="
                              width: 100%;
                              text-align: center;
                              margin: 40px 0;
                            "
                          >
                            <img
                              v-if="
                                item.cover ==
                                'https://awhf-oss-img.oss-cn-beijing.aliyuncs.com/homeassociation/%E6%9A%82%E6%97%A0%E5%9B%BE%E7%89%87.png'
                              "
                              src="https://awhf-oss-img.oss-cn-beijing.aliyuncs.com/homeassociation/rx.png"
                              referrerpolicy="no-referrer"
                              alt="临西医养结合中心"
                              class="pic"
                              style="height: 100px; width: 100px"
                            />
                            <img
                              v-else
                              :src="item.cover"
                              referrerpolicy="no-referrer"
                              alt="临西医养结合中心"
                              class="pic"
                              style="height: 100px; width: 100px"
                            />
                          </div>

                          <span class="tit f14 text-center text-more">{{
                            item.title
                          }}</span>
                        </li>
                      </ul>
                    </div>

                    <div class="pagebar">
                      <div class="pagination">
                        <el-pagination
                          background
                          :page-size="9"
                          layout="prev, pager, next"
                          :total="flielist.length"
                          @size-change="handleSizeChange"
                          @current-change="handleCurrentChange"
                        />
                      </div>
                    </div>
                  </div>

                  <div v-else style="text-align: center">暂无数据</div>
                </div>
                <div v-else-if="centers.urlname == 'hunlianhn'">
                  <div v-if="flielist.length != 0">
                    <div class="albumlist">
                      <ul class="line-big clearfix">
                        <li
                          class="xm4 xs6 xl6"
                          v-for="(item, index) in tableData1()"
                          :key="index"
                          @click="imagesclick(item)"
                        >
                          <div
                            v-if="
                              item.cover !==
                              'https://awhf-oss-img.oss-cn-beijing.aliyuncs.com/homeassociation/%E6%9A%82%E6%97%A0%E5%9B%BE%E7%89%87.png'
                            "
                            style="
                              height: 210px;
                              position: relative;
                              overflow: hidden;
                              display: flex;
                              justify-content: center;
                            "
                          >
                            <img
                              :src="item.cover"
                              referrerpolicy="no-referrer"
                              alt="临西医养结合中心"
                              class="pic"
                              style="height: 100%; width: auto"
                            />
                          </div>
                          <img
                            v-else
                            :src="item.cover"
                            referrerpolicy="no-referrer"
                            alt="临西医养结合中心"
                            class="pic"
                            style="height: 210px"
                          />

                          <span class="tit f14 text-center text-more">{{
                            item.title
                          }}</span>
                        </li>
                      </ul>
                    </div>

                    <div class="pagebar">
                      <div class="pagination">
                        <el-pagination
                          background
                          :page-size="9"
                          layout="prev, pager, next"
                          :total="flielist.length"
                          @size-change="handleSizeChange"
                          @current-change="handleCurrentChange"
                        />
                      </div>
                    </div>
                  </div>

                  <div v-else style="text-align: center">暂无数据</div>
                </div>
                <!-- <div v-else-if="centers.urlname == 'hunlians'">
                  <div v-if="flielist.length != 0">
                    <div class="albumlist">
                      <ul class="line-big clearfix">
                        <li
                          class="xm4 xs6 xl6"
                          v-for="(item, index) in tableData1()"
                          :key="index"
                          @click="imagesclick(item)"
                          style="text-align: center"
                        >
                          <img
                            :src="item.cover"
                            referrerpolicy="no-referrer"
                            alt="临西医养结合中心"
                            class="pic"
                            style="width: 60%"
                          />
                          <span class="tit f14 text-center text-more">{{
                            item.id
                          }}</span>
                        </li>
                      </ul>
                    </div>

                    <div class="pagebar">
                      <div class="pagination">
                        <el-pagination
                          background
                          :page-size="9"
                          layout="prev, pager, next"
                          :total="flielist.length"
                          @size-change="handleSizeChange"
                          @current-change="handleCurrentChange"
                        />
                      </div>
                    </div>
                  </div>

                  <div v-else style="text-align: center">暂无数据</div>
                </div> -->
                <div v-else>
                  <div v-if="flielist.length != 0">
                    <div class="albumlist">
                      <ul class="line-big clearfix">
                        <li
                          class="xm4 xs6 xl6"
                          v-for="(item, index) in tableData1()"
                          :key="index"
                          @click="imagesclick(item)"
                        >
                          <img
                            :src="item.cover"
                            referrerpolicy="no-referrer"
                            alt="临西医养结合中心"
                            class="pic"
                            style="height: 210px"
                          />
                          <span class="tit f14 text-center text-more">{{
                            item.title
                          }}</span>
                        </li>
                      </ul>
                    </div>

                    <div class="pagebar">
                      <div class="pagination">
                        <el-pagination
                          background
                          :page-size="9"
                          layout="prev, pager, next"
                          :total="flielist.length"
                          @size-change="handleSizeChange"
                          @current-change="handleCurrentChange"
                        />
                      </div>
                    </div>
                  </div>

                  <div v-else style="text-align: center">暂无数据</div>
                </div>
              </div>
            </div>
            <div v-if="listtemplate == 'about'">
              <div v-if="centers.urlname == 'hunlians'">
                <div style="display: flex; justify-content: center">
                  <div style="width: 20%">
                    <div>
                      <img
                        :src="hunlianlist.head_img"
                        referrerpolicy="no-referrer"
                        alt=""
                        style="
                          width: 150px;
                          margin-top: 30px;
                          margin-bottom: 10px;
                        "
                      />
                    </div>
                  </div>
                  <div style="width: 80%">
                    <div style="padding: 10px 0; font-weight: bold">
                      个人简介
                    </div>
                    <div style="display: flex">
                      <div style="width: 50%; line-height: 30px">
                        <div>
                          <span style="letter-spacing: 5px">性别：</span>
                          {{ hunlianlist.sex == 1 ? "男" : "女" }}
                        </div>
                        <div>
                          <span style="letter-spacing: 5px">年龄：</span
                          >{{ hunlianlist.age }}
                        </div>
                        <div>
                          <span style="letter-spacing: 5px">属相：</span
                          >{{ hunlianlist.zodiac }}
                        </div>
                        <div>房车情况：{{ hunlianlist.rvsituation }}</div>
                        <div>婚姻状况：{{ hunlianlist.maritalstatus }}</div>
                        <div>家庭成员：{{ hunlianlist.familymembers }}</div>
                      </div>
                      <div
                        style="
                          width: 50%;
                          line-height: 30px;
                          padding-left: 30px;
                        "
                      >
                        <div>
                          <span style="letter-spacing: 5px">身高：</span
                          >{{ hunlianlist.height }}米
                        </div>
                        <div>
                          <span style="letter-spacing: 5px">体重：</span
                          >{{ hunlianlist.weight }}Kg
                        </div>
                        <div>
                          <span style="letter-spacing: 5px">学历：</span
                          >{{ hunlianlist.qualification }}
                        </div>
                        <div>毕业学校：{{ hunlianlist.graduationschool }}</div>
                        <div>健康情况：{{ hunlianlist.healthcondition }}</div>
                        <div>经济状况：{{ hunlianlist.economicsituation }}</div>
                        <div>个人爱好：{{ hunlianlist.personalhobbies }}</div>
                      </div>
                    </div>
                    <div>
                      <div style="padding: 10px 0; font-weight: bold">
                        择偶要求
                      </div>
                      <div>{{ hunlianlist.matchmakingrequirements }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else-if="centers.urlname == 'hunlianhn'">
                <div style="display: flex; justify-content: center">
                  <div style="width: 20%">
                    <div>
                      <img
                        :src="hunlianhnlist.head_img"
                        referrerpolicy="no-referrer"
                        alt=""
                        style="width: 150px; margin-bottom: 10px"
                      />
                    </div>
                  </div>
                  <div style="width: 80%">
                    <div style="padding: 10px 0; font-weight: bold">
                      个人简介
                    </div>
                    <div style="display: flex">
                      <div style="width: 50%; line-height: 30px">
                        <div>
                          <span style="letter-spacing: 5px">姓名：</span
                          >{{ hunlianhnlist.cname }}
                        </div>
                        <div>
                          <span style="letter-spacing: 5px">性别：</span>
                          {{ hunlianhnlist.sex == 1 ? "男" : "女" }}
                        </div>
                        <div>
                          <span style="letter-spacing: 5px">年龄：</span
                          >{{ hunlianhnlist.age }}
                        </div>
                        <div>
                          <span style="letter-spacing: 5px">特长:</span
                          >{{ hunlianhnlist.specialties }}
                        </div>
                        <div>
                          <span style="letter-spacing: 5px">爱好:</span
                          >{{ hunlianhnlist.hobby }}
                        </div>
                      </div>
                      <div
                        style="
                          width: 50%;
                          line-height: 30px;
                          padding-left: 30px;
                        "
                      >
                        <div>工作年限：{{ hunlianhnlist.workyear }}</div>
                        <div>最高学历：{{ hunlianhnlist.qualification }}</div>
                        <div>
                          毕业院校及专业：
                          {{ hunlianhnlist.graduationschool }}
                        </div>
                        <div>
                          是否有长期疾病或残疾：{{ hunlianhnlist.disease }}
                        </div>
                        <div>
                          是否能够参与各种志愿服务活动：{{
                            hunlianhnlist.activities
                          }}
                        </div>
                      </div>
                    </div>
                    <div>
                      <div style="padding: 10px 0; font-weight: bold">
                        与婚恋服务相关的经历
                      </div>
                      <div>{{ hunlianhnlist.workexperience }}</div>
                    </div>
                    <div>
                      <div style="padding: 10px 0; font-weight: bold">
                        您为何想成为红娘志愿者
                      </div>
                      <div>{{ hunlianhnlist.whybecome }}</div>
                    </div>
                    <div>
                      <div style="padding: 10px 0; font-weight: bold">
                        您对婚恋服务的理解是什么
                      </div>
                      <div>{{ hunlianhnlist.understandmarriage }}</div>
                    </div>
                    <div>
                      <div style="padding: 10px 0; font-weight: bold">
                        您认为自己能为婚恋服务中心带来什么
                      </div>
                      <div>{{ hunlianhnlist.youbring }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else>
                <div class="content page30" id="maximg" v-if="content">
                  <div
                    style="
                      height: 45px;
                      text-align: right;
                      color: #b9b9b9;
                      font-size: 13px;
                    "
                  >
                    浏览量：{{ liulan }}次
                  </div>

                  <span v-html="content"></span>
                </div>
                <div v-else style="text-align: center">暂无数据</div>
              </div>
              <!-- </div> -->
            </div>
          </div>
          <!-- -------------------- -->
        </div>
      </div>
    </div>
    <!-- <Footer /> -->
    <meta name="referrer" content="no-referrer" />
  </div>
</template>
<script>
import axios from "axios";
import { onMounted, reactive, toRefs, ref, nextTick, watch } from "vue";
// import { useRoute } from "vue-router";
import router from "../../router";
// import { TabsPaneContext } from "element-plus";
// import FenYe from "@/components/common/fenye.vue";
export default {
  name: "NewsList",
  //   components: { FenYe },
  props: {
    msg: String,
  },
  setup() {
    // const $router = useRoute();
    // const route = useRoute();

    const $routers = window.history.state;
    // const servicecate = ref('')
    const state = reactive({
      //   host: "http://local.s.awhfjt.com",
      host: "https://s.awhfjt.com",
      flielist: [],
      breadcrumbs: false,
      breadcrumb1: [],
      breadcrumb2: [],
      leftlist: [],
      centers: [],
      servicecate: "",
      viplist: "",
      sexlist: "",
      inname: "",
      age1: "",
      age2: "",
      honame: "",
      content: "",
      listtemplate: "",
      limit: 5,
      limit1: 9,
      page: 1,
      imgshow: false,
      jiaxieshow: false,
      img: "",
      options: [
        {
          value: "1",
          label: "母婴照护服务员",
        },
        {
          value: "2",
          label: "养老照护服务员",
        },
        {
          value: "4",
          label: "居家家政服务员",
        },
        {
          value: "5",
          label: "一般家政服务员",
        },
      ],
      vipoptions: [
        {
          value: "1",
          label: "单位会员",
        },
        {
          value: "2",
          label: "个人会员",
        },
      ],
      sexoptions: [
        {
          value: "1",
          label: "男",
        },
        {
          value: "2",
          label: "女",
        },
      ],
      hongnsex: "",
      hongnage1: "",
      hongnage2: "",
      minzhengselet: "",
      minzhenginput: "",
      vipselet: "",
      vipinput: "",
      liulan: 0,
      hunlianlist: {},
      hunlianhnlist: {},
      ceshi: localStorage.getItem("cachedData"),
    });
    onMounted(() => {
      nextTick(() => {
        // 监听布局配置弹窗点击打开
      });
    });
    watch(() => {
      //   console.log("route.state>>>>>>>>>>>>:", route);
      console.log("QQQQQQQQQQQQQQQQQQQQQQQQhistory", $routers);
      //   const $routers = history.state;
      //   console.log("QQQQQQQQQQQQQQQQQQQQQQQQparams", $router);
      //下次尝试判断条件为$router.query&&$routers.state.data
      if ($routers.params.data) {
        state.leftlist = JSON.parse($routers.params.name);
        state.centers = JSON.parse($routers.params.data);

        console.log("QQQQQQQQQQQQQQQQQQQQQQQQ", state.centers.urlname);

        state.listtemplate = state.centers.listtemplate;
        let a;
        let b;
        if ($routers.params.name1) {
          a = JSON.parse($routers.params.name);
          b = JSON.parse($routers.params.name1);
          state.breadcrumbs = true;
          state.breadcrumb1 = a;
          state.breadcrumb2 = b;
        } else {
          a = JSON.parse($routers.params.name);
          state.breadcrumbs = false;
          state.breadcrumb1 = a;
        }
        if (state.centers.listtemplate == "about") {
          var p = [];
          axios
            .get(
              // "http://local.s.awhfjt.com/web-sites/familyassociation/articles?cateid=" +
              state.host +
                "/web-sites/familyassociation/articles?cateid=" +
                state.centers.id
            )
            .then(function (response) {
              p = response.data;
              if (p.length != 0) {
                axios
                  .get(
                    state.host +
                      "/web-sites/familyassociation/articlesinfo?id=" +
                      p[0].id
                  )
                  .then(function (response) {
                    state.content = response.data[0].data.content;
                    axios
                      .get(
                        "https://s.awhfjt.com/web-sites/familyassociation/addvisits?articles=" +
                          p[0].id
                      )
                      //   .get("https://s.awhfjt.com/web-sites/familyassociation/carouselchart")
                      .then(function (response) {
                        console.log("文章累计量+1", response);
                        // that.img = response.data;
                        axios
                          .get(
                            "https://s.awhfjt.com/web-sites/familyassociation/selectvisits?articles=" +
                              p[0].id
                          )
                          //   .get("https://s.awhfjt.com/web-sites/familyassociation/carouselchart")
                          .then(function (response) {
                            console.log("查询文章累计量", response);
                            state.liulan = response.data.data.visits;

                            // that.img = response.data;
                          })
                          .catch(function (error) {
                            console.log(error);
                          });
                      })
                      .catch(function (error) {
                        console.log(error);
                      });
                  })
                  .catch(function (error) {
                    console.log(error);
                  });
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        } else {
          if (state.centers.urlname == "jiazheng") {
            axios
              .get(state.host + "/web-sites/familyassociation/thtrain")
              .then(function (response) {
                state.flielist = response.data;
                state.total = response.data.length;
              })
              .catch(function (error) {
                console.log(error);
              });
          } else if (state.centers.urlname == "vipmess") {
            axios
              .get(state.host + "/web-sites/familyassociation/institution")
              .then(function (response) {
                state.flielist = response.data;
                state.total = response.data.length;
              })
              .catch(function (error) {
                console.log(error);
              });
          } else if (state.centers.urlname == "hospital") {
            axios
              .get(state.host + "/web-sites/familyassociation/hospital")
              .then(function (response) {
                state.flielist = response.data;
                state.total = response.data.length;
              })
              .catch(function (error) {
                console.log(error);
              });
          } else if (state.centers.urlname == "fengcai") {
            axios
              .get(
                // "http://local.s.awhfjt.com/web-sites/familyassociation/articles?cateid=" +
                state.host +
                  "/web-sites/familyassociation/articles?cateid=" +
                  state.centers.id
              )
              .then(function (response) {
                var a = [];
                response.data.map((res) => {
                  if (res.is_audit === "1" && res.auditstatus === "2") {
                    a.push({
                      auditstatus: res.auditstatus,
                      cate: res.cate,
                      cover: res.cover,
                      created_times: res.created_times,
                      day: res.day,
                      id: res.id,
                      is_audit: res.is_audit,
                      status: res.status,
                      tabdashiji: res.tabdashiji,
                      tabfengcai: res.tabfengcai,
                      title: res.title,
                      year_month: res.year_month,
                    });
                  }
                });
                state.flielist = a;
                state.total = a.length;
              })
              .catch(function (error) {
                console.log(error);
              });
          } else if (state.centers.urlname == "dashiji") {
            axios
              .get(
                // "http://local.s.awhfjt.com/web-sites/familyassociation/articles?cateid=" +
                state.host +
                  "/web-sites/familyassociation/articles?cateid=" +
                  state.centers.id
              )
              .then(function (response) {
                var a = [];
                response.data.map((res) => {
                  if (res.tabdashiji === "2") {
                    a.push({
                      auditstatus: res.auditstatus,
                      cate: res.cate,
                      cover: res.cover,
                      created_times: res.created_times,
                      day: res.day,
                      id: res.id,
                      is_audit: res.is_audit,
                      status: res.status,
                      tabdashiji: res.tabdashiji,
                      tabfengcai: res.tabfengcai,
                      title: res.title,
                      year_month: res.year_month,
                    });
                  }
                });
                state.flielist = a;
                state.total = a.length;
              })
              .catch(function (error) {
                console.log(error);
              });
          } else if (state.centers.urlname == "fuli") {
            axios
              .get(
                // "http://local.s.awhfjt.com/web-sites/familyassociation/getcoupon"
                state.host + "/web-sites/familyassociation/getcoupon"
              )
              .then(function (response) {
                console.log(
                  "fuli-------------------------",
                  response.data.data
                ); //response.data.qrcode
                // var a = [];
                // response.data.map((res) => {
                //   if (res.tabdashiji === "2") {
                //     a.push({
                //       auditstatus: res.auditstatus,
                //       cate: res.cate,
                //       cover: res.cover,
                //       created_times: res.created_times,
                //       day: res.day,
                //       id: res.id,
                //       is_audit: res.is_audit,
                //       status: res.status,
                //       tabdashiji: res.tabdashiji,
                //       tabfengcai: res.tabfengcai,
                //       title: res.title,
                //       year_month: res.year_month,
                //     });
                //   }
                // });
                state.flielist = response.data.data;
                state.total = response.data.data.length;
                state.img = response.data.qrcode;
              })
              .catch(function (error) {
                console.log(error);
              });
          } else if (state.centers.urlname == "hunlians") {
            axios
              .get(state.host + "/web-sites/familyassociation/marriage"+"?page="+1+"&size="+100000)
              // .get(
              //   "http://local.s.awhfjt.com/web-sites/familyassociation/marriage"+"?page="+1+"&size="+100000
              // )
              .then(function (response) {
                console.log('婚恋------',response)
                state.flielist = response.data.data;
                state.total = response.data.data.length;
              })
              .catch(function (error) {
                console.log(error);
              });
          } else if (state.centers.urlname == "hunlianhn") {
            axios
              .get(state.host + "/web-sites/familyassociation/matchmaker"+"?page="+1+"&size="+100000)
              // .get(
              //   "http://local.s.awhfjt.com/web-sites/familyassociation/matchmaker"+"?page="+1+"&size="+100000
              // )
              .then(function (response) {
                state.flielist = response.data.data;
                state.total = response.data.data.length;
              })
              .catch(function (error) {
                console.log(error);
              });
          } else {
            axios
              .get(
                // "http://local.s.awhfjt.com/web-sites/familyassociation/articles?cateid=" +
                state.host +
                  "/web-sites/familyassociation/articles?cateid=" +
                  state.centers.id
              )
              .then(function (response) {
                state.flielist = response.data;
                state.total = response.data.length;
              })
              .catch(function (error) {
                console.log(error);
              });
          }
        }
      }
      if ($routers.params.type) {
        state.leftlist = JSON.parse($routers.params.name);
        let a;
        let b;
        if ($routers.params.name1) {
          a = JSON.parse($routers.params.name);
          b = JSON.parse($routers.params.name1);
          state.breadcrumbs = true;
          state.breadcrumb1 = a;
          state.breadcrumb2 = b;
        } else {
          a = JSON.parse($routers.params.name);
          state.breadcrumbs = false;
          state.breadcrumb1 = a;
        }
        if ($routers.params.type == "about") {
          state.listtemplate = "about";
          state.content = "";
          if (state.centers.urlname == "vipmess") {
            axios
              .get(
                "https://s.awhfjt.com/web-sites/familyassociation/institution"
              )
              .then(function (response) {
                console.log("单位会员1111", $routers.params.id, response.data);
                response.data.map((item) => {
                  if (item.id == $routers.params.id) {
                    state.content = item.introduction;
                  }
                });
              })
              .catch(function (error) {
                console.log(error);
              });
            axios
              .get(
                state.host +
                  "/web-sites/familyassociation/articlesinfo?id=" +
                  $routers.params.id
              )
              .then(function () {
                // state.content = response.data[0].data.content;
                axios
                  .get(
                    "https://s.awhfjt.com/web-sites/familyassociation/addvisits?articles=" +
                      $routers.params.id
                  )
                  //   .get("https://s.awhfjt.com/web-sites/familyassociation/carouselchart")
                  .then(function (response) {
                    console.log("文章累计量+1", response);
                    // that.img = response.data;
                    axios
                      .get(
                        "https://s.awhfjt.com/web-sites/familyassociation/selectvisits?articles=" +
                          $routers.params.id
                      )
                      //   .get("https://s.awhfjt.com/web-sites/familyassociation/carouselchart")
                      .then(function (response) {
                        console.log("查询文章累计量", response);
                        state.liulan = response.data.data.visits;

                        // that.img = response.data;
                      })
                      .catch(function (error) {
                        console.log(error);
                      });
                  })
                  .catch(function (error) {
                    console.log(error);
                  });
              })
              .catch(function (error) {
                console.log(error);
              });
          } else {
            axios
              .get(
                state.host +
                  "/web-sites/familyassociation/articlesinfo?id=" +
                  $routers.params.id
              )
              .then(function (response) {
                state.content = response.data[0].data.content;
                axios
                  .get(
                    "https://s.awhfjt.com/web-sites/familyassociation/addvisits?articles=" +
                      $routers.params.id
                  )
                  //   .get("https://s.awhfjt.com/web-sites/familyassociation/carouselchart")
                  .then(function (response) {
                    console.log("文章累计量+1", response);
                    // that.img = response.data;
                    axios
                      .get(
                        "https://s.awhfjt.com/web-sites/familyassociation/selectvisits?articles=" +
                          $routers.params.id
                      )
                      //   .get("https://s.awhfjt.com/web-sites/familyassociation/carouselchart")
                      .then(function (response) {
                        console.log("查询文章累计量", response);
                        state.liulan = response.data.data.visits;

                        // that.img = response.data;
                      })
                      .catch(function (error) {
                        console.log(error);
                      });
                  })
                  .catch(function (error) {
                    console.log(error);
                  });
              })
              .catch(function (error) {
                console.log(error);
              });
          }
        }
      }
    });
    function leftclick1(row) {
      state.listtemplate = row.listtemplate;
      console.log("===========================", $routers);
      router.push({
        path: "/" + row.urlname,
        state: {
          params: {
            data: JSON.stringify(row),
            name: JSON.stringify(row),
            a: Math.random(),
          },
        },
        component: () =>
          import("@/components/list/" + row.listtemplate + ".vue"),
      });
    }
    function leftclick2(row, row1) {
      state.listtemplate = row.listtemplate;
      router.push({
        path: "/" + row1.urlname + "/" + row.urlname,
        state: {
          params: {
            data: JSON.stringify(row),
            name: JSON.stringify(row1),
            name1: JSON.stringify(row),
            a: Math.random(),
          },
        },
        component: () =>
          import("@/components/list/" + row.listtemplate + ".vue"),
      });
    }
    function leftclick3(row) {
      state.listtemplate = row.listtemplate;
      router.push({
        path: "/" + row.urlname,
        state: {
          params: {
            data: JSON.stringify(row),
            name: JSON.stringify(row),
            a: Math.random(),
          },
        },
        component: () =>
          import("@/components/list/" + row.listtemplate + ".vue"),
      });
    }
    function leftclick4(row, row1) {
      state.listtemplate = row.listtemplate;
      router.push({
        path: "/" + row1.urlname + "/" + row.urlname,
        state: {
          params: {
            data: JSON.stringify(row),
            name: JSON.stringify(row1),
            name1: JSON.stringify(row),
            a: Math.random(),
          },
        },
        component: () =>
          import("@/components/list/" + row.listtemplate + ".vue"),
      });
    }
    function newsclick(row) {
      state.listtemplate = "about";
      state.content = "";
      if (state.centers.urlname == "hunlians") {
        axios
          .get(
            state.host +
              "/web-sites/familyassociation/marriageinfo?mid=" +
              row.id
            // "http://local.s.awhfjt.com/web-sites/familyassociation/marriageinfo?mid=" +
            //   row.id
          )
          .then(function (response) {
            console.log("婚恋", response.data);
            state.hunlianlist = response.data;
            console.log("家政人员", row);
            // if (state.centers.urlname == "vipmess") {
            //   state.content = row.introduction;
            // } else if (state.centers.urlname == "hospital") {
            //   state.content = row.introduction;
            // } else if (state.centers.urlname == "hunlians") {
            //   state.content = row.introduction;
            // } else {
            //   state.content = response.data[0].data.content;
            // }
          })
          .catch(function (error) {
            console.log(error);
          });
      } else {
        axios
          .get(
            state.host +
              "/web-sites/familyassociation/articlesinfo?id=" +
              row.id
          )
          .then(function (response) {
            // state.content = response.data[0].data.content;
            if (state.centers.urlname == "vipmess") {
              state.content = row.introduction;
            } else if (state.centers.urlname == "hospital") {
              state.content = row.introduction;
            } else {
              state.content = response.data[0].data.content;
            }
            axios
              .get(
                "https://s.awhfjt.com/web-sites/familyassociation/addvisits?articles=" +
                  row.id
              )
              //   .get("https://s.awhfjt.com/web-sites/familyassociation/carouselchart")
              .then(function (response) {
                console.log("文章累计量+1", response);
                // that.img = response.data;
                axios
                  .get(
                    "https://s.awhfjt.com/web-sites/familyassociation/selectvisits?articles=" +
                      row.id
                  )
                  //   .get("https://s.awhfjt.com/web-sites/familyassociation/carouselchart")
                  .then(function (response) {
                    console.log("查询文章累计量", response);
                    state.liulan = response.data.data.visits;

                    // that.img = response.data;
                  })
                  .catch(function (error) {
                    console.log(error);
                  });
              })
              .catch(function (error) {
                console.log(error);
              });
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    }
    function imagesclick(row) {
      state.listtemplate = "about";
      state.content = "";
      if (state.centers.urlname == "hunlianhn") {
        axios
          .get(
            state.host +
              "/web-sites/familyassociation/matchmakerinfo?mid=" +
              row.id
            // "http://local.s.awhfjt.com/web-sites/familyassociation/matchmakerinfo?mid=" +
            //   row.id
          )
          .then(function (response) {
            console.log("婚恋", response.data);
            state.hunlianhnlist = response.data;
            console.log("家政人员", row);
            // if (state.centers.urlname == "vipmess") {
            //   state.content = row.introduction;
            // } else if (state.centers.urlname == "hospital") {
            //   state.content = row.introduction;
            // } else if (state.centers.urlname == "hunlians") {
            //   state.content = row.introduction;
            // } else {
            //   state.content = response.data[0].data.content;
            // }
          })
          .catch(function (error) {
            console.log(error);
          });
      } else {
        axios
          .get(
            state.host +
              "/web-sites/familyassociation/articlesinfo?id=" +
              row.id
          )
          .then(function (response) {
            // state.content = response.data[0].data.content;
            if (state.centers.urlname == "vipmess") {
              state.content = row.introduction;
            } else if (state.centers.urlname == "hospital") {
              state.content = row.introduction;
            } else {
              state.content = response.data[0].data.content;
            }
            axios
              .get(
                "https://s.awhfjt.com/web-sites/familyassociation/addvisits?articles=" +
                  row.id
              )
              //   .get("https://s.awhfjt.com/web-sites/familyassociation/carouselchart")
              .then(function (response) {
                console.log("文章累计量+1", response);
                // that.img = response.data;
                axios
                  .get(
                    "https://s.awhfjt.com/web-sites/familyassociation/selectvisits?articles=" +
                      row.id
                  )
                  //   .get("https://s.awhfjt.com/web-sites/familyassociation/carouselchart")
                  .then(function (response) {
                    console.log("查询文章累计量", response);
                    state.liulan = response.data.data.visits;

                    // that.img = response.data;
                  })
                  .catch(function (error) {
                    console.log(error);
                  });
              })
              .catch(function (error) {
                console.log(error);
              });
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    }
    function init() {
      //   var that = this;
      axios
        .get(
          state.host +
            "/web-sites/familyassociation/articles?title=测试&cateid=63&page=1&size=2"
        )
        .then(function (response) {
          state.flielist = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    //--------------------------
    const tableData = () => {
      return state.flielist.filter(
        (item, index) =>
          index < state.page * state.limit &&
          index >= state.limit * (state.page - 1)
      );
    };
    const tableData1 = () => {
      return state.flielist.filter(
        (item, index) =>
          index < state.page * state.limit1 &&
          index >= state.limit1 * (state.page - 1)
      );
    };
    const handleSizeChange = (val) => {
      console.log(`${val} items per page`);
      //   state.flielist.filter(
      //     (item, index) => index < val * 1 && index >= 1 * (val - 1)
      //   );
    };
    const handleCurrentChange = (val) => {
      console.log(`current page1: ${val}`);
      state.page = val;
      tableData();
      tableData1();
    };
    const selectchange = (val) => {
      state.minzhengselet = val;
      axios
        .get(
          state.host +
            "/web-sites/familyassociation/thtrain?servicecate=" +
            state.minzhengselet +
            "&thname=" +
            state.minzhenginput
        )
        .then(function (response) {
          state.flielist = response.data;
          state.total = response.data.length;
        })
        .catch(function (error) {
          console.log(error);
        });
    };
    const sexchange = (val) => {
      console.log("性别选择", val);
      state.hongnsex = val;
      axios
        .get(
          state.host +
            "/web-sites/familyassociation/marriage" + "?page="+ 1 + "&size=" + 100000 + "&masex=" +
            //   "http://local.s.awhfjt.com/web-sites/familyassociation/marriage?masex=" +
            state.hongnsex +
            "&maminage=" +
            state.hongnage1 +
            "&mamaxage=" +
            state.hongnage2 
        )
        .then(function (response) {
          state.flielist = response.data.data;
          state.total = response.data.data.length;
        })
        .catch(function (error) {
          console.log(error);
        });
    };
    const vipchange = (val) => {
      state.vipselet = val;
      //   axios
      //     .get(
      //       state.host +
      //         "/web-sites/familyassociation/thtrain?servicecate=" +
      //         state.vipselet +
      //         "&thname=" +
      //         state.vipinput
      //     )
      //     .then(function (response) {
      //       state.flielist = response.data;
      //       state.total = response.data.length;
      //     })
      //     .catch(function (error) {
      //       console.log(error);
      //     });
      axios
        .get(
          "https://s.awhfjt.com/web-sites/familyassociation/institution?viptype=" +
            state.vipselet +
            "&inname=" +
            state.vipinput
        )
        .then(function (response) {
          state.flielist = response.data;
          state.total = response.data.length;
        })
        .catch(function (error) {
          console.log(error);
        });
    };
    const thnameinput = (val) => {
      state.minzhenginput = val;
      axios
        .get(
          state.host +
            "/web-sites/familyassociation/thtrain?servicecate=" +
            state.minzhengselet +
            "&thname=" +
            state.minzhenginput
        )
        .then(function (response) {
          state.flielist = response.data;
          state.total = response.data.length;
        })
        .catch(function (error) {
          console.log(error);
        });
    };
    const innameinput = (val) => {
      state.vipinput = val;
      axios
        .get(
          state.host + "/web-sites/familyassociation/institution?inname=" + val
        )
        .then(function (response) {
          state.flielist = response.data;
          state.total = response.data.length;
        })
        .catch(function (error) {
          console.log(error);
        });
    };
    const ageinput1 = (val) => {
      console.log("最小年龄限制", val);
      state.hongnage1 = val;
      axios
        .get(
          state.host +
            "/web-sites/familyassociation/marriage" + "?page="+ 1 + "&size=" + 100000 + "&masex=" +
            //   "http://local.s.awhfjt.com/web-sites/familyassociation/marriage?masex=" +
            state.hongnsex +
            "&maminage=" +
            state.hongnage1 +
            "&mamaxage=" +
            state.hongnage2 
        )
        .then(function (response) {
          state.flielist = response.data.data;
          state.total = response.data.data.length;
        })
        .catch(function (error) {
          console.log(error);
        });
    };
    const ageinput2 = (val) => {
      console.log("最大年龄限制", val);
      state.hongnage2 = val;
      axios
        .get(
          state.host +
            "/web-sites/familyassociation/marriage" + "?page="+ 1 + "&size=" + 100000 + "&masex=" +
            //   "http://local.s.awhfjt.com/web-sites/familyassociation/marriage?masex=" +
            state.hongnsex +
            "&maminage=" +
            state.hongnage1 +
            "&mamaxage=" +
            state.hongnage2 
        )
        .then(function (response) {
          state.flielist = response.data.data;
          state.total = response.data.data.length;
        })
        .catch(function (error) {
          console.log(error);
        });
    };
    const honameinput = (val) => {
      axios
        .get(state.host + "/web-sites/familyassociation/hospital?honame=" + val)
        .then(function (response) {
          state.flielist = response.data;
          state.total = response.data.length;
        })
        .catch(function (error) {
          console.log(error);
        });
    };
    // const activeNamefengcai = ref("first"); //免费
    const activeNamedashiji = ref("second"); //市级

    // const handleClickfengcai = (val) => {
    //   axios
    //     .get(
    //       //   "http://local.s.awhfjt.com/web-sites/familyassociation/articles?cateid=" +
    //       state.host +"/web-sites/familyassociation/articles?cateid=" +
    //         state.centers.id
    //     )
    //     .then(function (response) {
    //       var a = [];
    //       if (val.props.name == "first") {
    //         response.data.map((res) => {
    //           if (
    //             res.tabfengcai === "1" &&
    //             res.is_audit === "1" &&
    //             res.auditstatus === "2"
    //           ) {
    //             a.push({
    //               auditstatus: res.auditstatus,
    //               cate: res.cate,
    //               cover: res.cover,
    //               created_times: res.created_times,
    //               day: res.day,
    //               id: res.id,
    //               is_audit: res.is_audit,
    //               status: res.status,
    //               tabdashiji: res.tabdashiji,
    //               tabfengcai: res.tabfengcai,
    //               title: res.title,
    //               year_month: res.year_month,
    //             });
    //           }
    //         });
    //       } else {
    //         response.data.map((res) => {
    //           if (
    //             res.tabfengcai === "2" &&
    //             res.is_audit === "1" &&
    //             res.auditstatus === "2"
    //           ) {
    //             a.push({
    //               auditstatus: res.auditstatus,
    //               cate: res.cate,
    //               cover: res.cover,
    //               created_times: res.created_times,
    //               day: res.day,
    //               id: res.id,
    //               is_audit: res.is_audit,
    //               status: res.status,
    //               tabdashiji: res.tabdashiji,
    //               tabfengcai: res.tabfengcai,
    //               title: res.title,
    //               year_month: res.year_month,
    //             });
    //           }
    //         });
    //       }
    //       state.flielist = a;
    //       state.total = a.length;
    //     })
    //     .catch(function (error) {
    //       console.log(error);
    //     });
    // };
    const handleClickdashiji = (val) => {
      //   console.log(val.props.name);
      axios
        .get(
          //   "http://local.s.awhfjt.com/web-sites/familyassociation/articles?cateid=" +
          state.host +
            "/web-sites/familyassociation/articles?cateid=" +
            state.centers.id
        )
        .then(function (response) {
          var a = [];
          if (val.props.name == "first") {
            response.data.map((res) => {
              if (res.tabdashiji === "1") {
                a.push({
                  auditstatus: res.auditstatus,
                  cate: res.cate,
                  cover: res.cover,
                  created_times: res.created_times,
                  day: res.day,
                  id: res.id,
                  is_audit: res.is_audit,
                  status: res.status,
                  tabdashiji: res.tabdashiji,
                  tabfengcai: res.tabfengcai,
                  title: res.title,
                  year_month: res.year_month,
                });
              }
            });
          } else if (val.props.name == "second") {
            response.data.map((res) => {
              if (res.tabdashiji === "2") {
                a.push({
                  auditstatus: res.auditstatus,
                  cate: res.cate,
                  cover: res.cover,
                  created_times: res.created_times,
                  day: res.day,
                  id: res.id,
                  is_audit: res.is_audit,
                  status: res.status,
                  tabdashiji: res.tabdashiji,
                  tabfengcai: res.tabfengcai,
                  title: res.title,
                  year_month: res.year_month,
                });
              }
            });
          } else {
            response.data.map((res) => {
              if (res.tabdashiji === "3") {
                a.push({
                  auditstatus: res.auditstatus,
                  cate: res.cate,
                  cover: res.cover,
                  created_times: res.created_times,
                  day: res.day,
                  id: res.id,
                  is_audit: res.is_audit,
                  status: res.status,
                  tabdashiji: res.tabdashiji,
                  tabfengcai: res.tabfengcai,
                  title: res.title,
                  year_month: res.year_month,
                });
              }
            });
          }
          state.flielist = a;
          state.total = a.length;
        })
        .catch(function (error) {
          console.log(error);
        });
    };
    return {
      ...toRefs(state),
      init,
      leftclick1,
      leftclick2,
      leftclick3,
      leftclick4,
      newsclick,
      imagesclick,
      handleSizeChange,
      handleCurrentChange,
      tableData,
      tableData1,
      selectchange,
      vipchange,
      sexchange,
      innameinput,
      ageinput1,
      ageinput2,
      honameinput,
      thnameinput,
      //   activeNamefengcai,
      //   handleClickfengcai,
      activeNamedashiji,
      handleClickdashiji,
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
>>> input {
  background: #ffffff;
}
>>> .el-tabs__nav {
  width: 100%;
}
>>> .el-tabs__item {
  width: 100%;
}
/* >>> .el-tabs__active-bar {
  width: 50% !important;
} */
.zhezhao {
  background: #0000004a;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 100%;
  transition: all 0.3s ease;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.peopelwei {
  opacity: 0;
  height: 0px !important;
  width: 0px !important;
}
.conzhezhao:hover .zhezhao {
  bottom: 0;
  opacity: 1;
  transform: translateY(0px);
}
.conzhezhao:hover .zhezhao .peopelwei {
  opacity: 1;
  height: 150px !important;
  width: 150px !important;
}
</style>
